<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <section id="support" class="support">
    <h3 class="support__title">{{ $i18n('cs-section.cs-section-title') }}</h3>
    <p class="support__lead">
      {{ $i18n('cs-section.cs-section-description') }}
    </p>

    <section class="support-options">
      <div class="support-option">
        <div class="support-option__header">
          <h4>{{ $i18n('cs-section.cs-section-call-us') }}</h4>
          <div class="support-option__status" :class="[phonelinesOpen ? '--state-open' : '--state-closed']">
            {{ phonelinesOpen ? $i18n('cs-section.cs-section-now-open') : $i18n('cs-section.cs-section-closed') }}
          </div>
        </div>
        <div class="support-option__content">
          <p href="#" class="support-option__phone">
            <img src="~/static/general/phone-icon.svg" class="w-4 h-4" aria-hidden="true" />
            <a href="#">{{ $i18n('general.faq-call-us-phone-number') }}</a>
          </p>
        </div>
        <button class="support-option__link" @click="openModal">
          {{ $i18n('cs-section.cs-section-see-opening-times') }}
        </button>
      </div>
      <div class="support-option">
        <div class="support-option__header">
          <h4>{{ $i18n('cs-section.cs-section-mail-us') }}</h4>
        </div>
        <div class="support-option__content">
          <p>{{ $i18n('cs-section.cs-section-response-time-mail') }}</p>
        </div>
        <a href="mailto:info@parkos.com" class="support-option__link"> info@parkos.com </a>
      </div>
    </section>
    <OpeningTimes v-model="modal" />
  </section>
</template>

<script>
import OpeningTimes from '~/components/OpeningTime.vue';

export default {
  name: 'Support',
  components: {
    OpeningTimes,
  },
  data() {
    return {
      openOffice: true,
      modal: false,
    };
  },
  computed: {
    // eslint-disable-next-line consistent-return, vue/return-in-computed-property
    phonelinesOpen() {
      const now = new Date();
      const day = now.getDay();
      const { openingTimes, language } = this.$store.state;

      if (!openingTimes?.[language.lang]) return false;

      const { openingHours } = openingTimes[language.lang];
      const { startTime, endTime } = openingHours.find((o) => o.dayOfWeek === day);
      const start = new Date(`${now.toDateString()} ${startTime}`);
      const end = new Date(`${now.toDateString()} ${endTime}`);

      if (start <= end) {
        return now >= start && now <= end;
      }
    },
  },
  methods: {
    openModal() {
      this.modal = true;
    },
  },
};
</script>

<style lang="scss">
.--state-open {
  @apply text-[#20a200];
}

.--state-closed {
  @apply text-[#d92001];
}

.support {
  @apply w-full py-10 md:py-20;

  &__title {
    @apply mb-2;
    @apply text-xl md:text-2xl font-cocogoose;
  }

  &__lead {
    @apply text-md md:text-base;
  }
}

.support-options {
  @apply flex flex-col md:flex-row w-full gap-4 md:gap-8;
  @apply pt-8;
}

.support-option {
  @apply w-full border border-athens rounded bg-white p-5 md:p-7;

  &__header {
    @apply flex justify-between items-center mb-2;

    h4 {
      @apply text-base md:text-xl font-cocogoose;
    }
  }

  &__status {
    @apply flex items-center;
    @apply text-base;

    &:before {
      width: 6px;
      height: 6px;
      @apply block rounded-full bg-current mr-3;
      content: '';
    }
  }

  &__phone {
    @apply flex items-center gap-2;
    @apply text-base md:text-xl font-normal;
  }

  &__content {
    @apply mb-2;
  }

  &__link {
    @apply text-base text-blue-600;
  }
}
</style>
