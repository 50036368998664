<template>
  <div class="container page-content" v-html="$store.state.pageNotFoundContent" />
</template>
<script>
export default {
  name: 'Content',
};
</script>

<style lang="scss">
.page-content {
  @apply px-5 md:px-10 my-[50px];

  h1 {
    @apply text-[30px];
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-[30px] font-cocogoose;
  }

  ul {
    @apply list-disc list-inside;

    li {
      @apply text-[16px] leading-[1.75];
    }
  }

  a {
    @apply text-[#206bf6] hover:underline;
  }
}
</style>
