<template>
  <div v-if="show" class="modal">
    <!-- eslint-disable -->
    <div class="modal__backdrop" @click="closeModal()" @keydown.esc="closeModal()" />
    <!-- eslint-enable -->
    <div class="modal__dialog modal-animation">
      <div class="modal__header py-7 px-7">
        <slot name="header" />
        <button type="button" class="mb-auto modal__close" @click="closeModal()">
          <span class="leading-none close-btn">&times;</span>
        </button>
      </div>
      <div class="border-b-2 border-gray-100" />
      <div class="h-auto modal__body pb-7 px-7">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    show: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    closeModal() {
      this.show = false;
      this.$emit('closeModal', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    openModal() {
      this.show = true;
      document.querySelector('body').classList.add('overflow-hidden');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;

  &__backdrop {
    background-color: rgba(0, 0, 0, 0.48);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__dialog {
    background-color: #ffffff;
    position: relative;
    max-width: 440px;
    width: 100%;
    border-radius: 6px;
    z-index: 2;
    margin: auto;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.modal-animation {
  @media screen and (max-width: 770px) {
    animation-name: displaceContentMobile;
  }
  animation-name: displaceContent;

  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

/* slide down to make room for advertisements */

@keyframes displaceContentMobile {
  from {
    top: 0;
    transform: translateY(0em);
  }
  to {
    top: 50%;
    transform: translateY(-50%);
  }
}

@keyframes displaceContent {
  from {
    top: 0;
    transform: translateY(0em);
  }
  to {
    top: 40%;
    transform: translateY(-50%);
  }
}

.close-btn {
  font-size: 26px;
  font-weight: 400;
  color: #0e1017;
}
</style>
