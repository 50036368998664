<template>
  <div class="hidden md:block help-button-wrapper">
    <div
      ref="wrapper"
      role="menu"
      class="help-btn transition-bg-ease fixed right-4 inline-flex bottom-4 text-white z-40 rounded-full cursor-pointer"
      :class="{ hidden: isLoaded }"
      tabindex="0"
      @click="show"
      @keypress="show"
    >
      <button v-if="!isLoaded" class="help-button-wrapper__help-button">
        <svg
          position="right"
          class="sc-1k07fow-1 cbnSms"
          width="38px"
          height="38px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <path
            d="M10,18 L6,22 L6,18 L10,18 Z M17,6 C19.7614237,6 22,8.23857625 22,11 C22,13.7614237 19.7614237,16 17,16 L17,16 L7,16 C4.23857625,16 2,13.7614237 2,11 C2,8.23857625 4.23857625,6 7,6 L7,6 Z"
            transform="translate(12.000000, 14.000000) scale(-1, 1) translate(-12.000000, -14.000000) "
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    language: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isLoaded: false,
      isMounted: false,
      pointerStruct: null,
    };
  },
  computed: {
    isMobile() {
      if (process.server) {
        return true;
      }
      return this.$deviceDetector.isMobile();
    },
  },
  mounted() {
    this.isMounted = true;

    if (window.innerWidth < 990) {
      return;
    }
    window.addEventListener('scroll', this.handleScroll);
    setTimeout(() => {
      document.addEventListener('pointermove', this.handlePointerMove);
    }, 1000);
  },
  methods: {
    clearListeners() {
      window.removeEventListener('scroll', this.handleScroll);
      document.removeEventListener('pointermove', this.handlePointerMove);
    },
    handleScroll() {
      this.load(false);
      this.clearListeners();
    },
    handlePointerMove(event) {
      if (this.isLoaded) {
        this.clearListeners();
        return;
      }

      const boundingTarget = this.$refs.wrapper.getBoundingClientRect();
      this.pointerStruct = this.pointerStruct || {
        x: boundingTarget.x,
        y: boundingTarget.y,
      };
      const distToInitial = Math.abs(
        Math.sqrt((this.pointerStruct.x - boundingTarget.x) ** 2 + (this.pointerStruct.y - boundingTarget.y) ** 2),
      );
      const distNow = Math.abs(Math.sqrt((event.x - boundingTarget.x) ** 2 + (event.y - boundingTarget.y) ** 2));
      if (distNow < distToInitial) {
        this.load(false);
        return;
      }
      this.pointerStruct.x = event.x;
      this.pointerStruct.y = event.y;
    },
    show() {
      if (!this.isLoaded) {
        this.isLoading = true;
        this.load();
      } else {
        this.open();
      }
    },
    open() {
      window.zE('messenger', 'open');
      if (!this.isLoaded) {
        this.isLoaded = true;
      }
    },
    load(open = true) {
      if (this.isMobile || this.isLoaded) {
        return;
      }
      this.isLoaded = true;
      const script = document.createElement('script');
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${this.$config.zendeskKey}`;
      script.id = 'ze-snippet';
      script.addEventListener('load', () => {
        setTimeout(() => {
          window.zE('messenger', 'close');
          window.zE('messenger:set', 'locale', this.language);
          this.isLoading = false;
          if (open) {
            this.open();
          }
        });
      });
      document.body.appendChild(script);
    },
  },
};
</script>
<style lang="scss">
.help-button-wrapper {
  &__help-button {
    @apply rounded-full;
    width: 64px;
    height: 64px;
    background-color: rgb(31, 107, 245) !important;

    svg {
      @apply mx-auto;
      fill: #fff;
    }
  }
}
</style>
